
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'StoreSuggestionMsg',
    // components:{
    // },
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        goText: {
            type: String,
            default: null,
        },
        goUrl: {
            type: String,
            default: null,
        },
        cookie: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            showModal: true
        };
    },
    // created() {
    // },
    // mounted() {},
    // computed: {},
    methods: {
        closeModal(){
            this.showModal = false;
            if(this.cookie){
                let d = new Date();
                d.setDate(d.getDate() + 90); // valid for 90 days
                //d.setTime(d.getTime() + 90 * 24 * 60 * 60 * 1000);
                document.cookie = this.cookie.name + "=" + this.cookie.value + ";expires=" + d.toUTCString() + ";domain=" + this.cookie.domain + ";path=/"; 
            }
        }
    },
});
